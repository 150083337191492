<template>
  <div class="GlobalTable">
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="seachDataList"
      :currentPage="page.pageNum"
      :total="page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="撮合渠道" slot="matchChannelName" align="center">
        <template slot-scope="{ row }">
          <div style="display:flex;justify-content: center;">
            <span>{{ row.matchChannelName }}</span>
            <el-tag
              style="margin-left:10px"
              v-if="!row.channelIsEnable"
              type="info"
              size="mini"
              effect="dark"
            >
              已禁用
            </el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="门店商家" slot="companyName" align="center">
        <template slot-scope="{ row }">
          <div>{{ row.companyName }}</div>
          <div style="color: #0981ff;">门店数量：{{ row.storeNum }}个</div>
        </template>
      </el-table-column>
      <el-table-column label="扣款条件" slot="regionStr" align="center">
        <template slot-scope="{ row }">
          <el-tooltip
            class="item"
            effect="dark"
            v-if="row.regionStr"
            :content="row.regionStr"
            placement="top"
          >
            <div class="Remarks">
              {{ row.regionStr }}
            </div>
          </el-tooltip>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="最近修改人" slot="updateAdminName" align="center">
        <template slot-scope="{ row }">
          <span>{{row.updateAdminName}}-{{ row. updateAdminAccount}}</span>
        </template>
      </el-table-column>
      <el-table-column label="最近修改时间" slot="updateTime" align="center">
        <template slot-scope="{ row }">
          <span>{{row.updateTime}}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" slot="state" align="center">
        <template slot-scope="{ row }">
          <!-- 00：未生效，01：生效中，02：已关闭 -->
          <div>
            {{
              row.state == "00"
                ? "未生效"
                : row.state == "01"
                ? "生效中"
                : "已关闭"
            }}
          </div>
          <div v-if="!row.channelIsEnable && row.state == '01'">
            (渠道已禁用)
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <!-- 启用 -->
          <el-button
            v-if="row.state != '02'"
            size="mini"
            type="danger"
            @click="disableopen(row)"
            >关闭
          </el-button>
          <!-- 禁用 -->
          <el-button
            size="mini"
            v-if="row.state != '02'"
            type="primary"
            @click="editBtn(row)"
            >编辑
          </el-button>
          <div v-if="row.state == '02'">-已关闭状态无法操作-</div>
        </template>
      </el-table-column>
    </GlobalTable>
    <!-- 动态口令弹出框 -->
    <el-dialog
      :title="commandTitle"
      :visible.sync="commandShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="commandClosed"
    >
      <div class="enable-tip">
        <div>
          说明：关闭后不再向该回收商进行撮合交易扣款，包括渠道扣款和繁星扣款。
        </div>
        <div class="tip-sure">关闭后不可重新开启，是否确认关闭？</div>
      </div>
      <div class="command">
        <div style="width: 85px;">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandClosed">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="submit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!--弹框-->
    <el-dialog
      class="add-form"
      title="撮合交易"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="800px"
      @closed="handleDialogClosed"
    >
      <div style="color: #FF8080; font-size: 14px; margin-bottom: 20px;">
        说明：撮合交易生效后，系统会按照设置订单扣款条件对撮合回收商家进行收货次数扣减。
      </div>
      <div class="flex flex-jb" style="margin-bottom:20px;font-size:16px;">
        <div class="flex1 overflow-hiding">
          <span>撮合渠道：</span>
          <span style="color: #3a9aff;">{{ matchChannelName }}</span>
        </div>
        <div class="flex1 overflow-hiding">
          <span>撮合商家：</span>
          <span style="color: #3a9aff;">{{ merchantName }}</span>
        </div>
        <div class="flex1 overflow-hiding">
          <span>门店商家：</span>
          <span style="color: #3a9aff;">{{ companyName }}</span>
        </div>
      </div>
      <el-form
        ref="form"
        :rules="modelrules"
        :model="modelform"
        label-width="80px"
      >
        <!-- <el-form-item label="撮合渠道" prop="matchChannelId">
          <el-select
            v-model="modelform.matchChannelId"
            placeholder="请选择撮合渠道"
            :disabled="true"
            filterable
            clearable
          >
            <el-option
              v-for="item in channelSelectList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="撮合商家" prop="merchantId">
          <el-select
            v-model="modelform.merchantId"
            placeholder="请选择回收商家"
            :disabled="true"
            filterable
            clearable
          >
            <el-option
              v-for="item in allMerchantSelectList"
              :key="item.merchantId"
              :label="item.merchantName"
              :value="item.merchantId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="门店商家" prop="companyId">
          <el-select
            v-model="modelform.companyId"
            placeholder="请选择门店商家"
            :disabled="true"
            filterable
            clearable
          >
            <el-option
              v-for="item in alreadyCompanyList"
              :key="item.companyId"
              :label="item.companyName"
              :value="item.companyId"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="扣款条件" required style="margin-bottom: 0;">
          <el-button
            style="float: right;"
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="addRule"
            >添加规则</el-button
          >
        </el-form-item>
        <GlobalTable
          style="margin-bottom:22px"
          ref="GlobalTable"
          myKey="deduction"
          :maxHeight="270"
          :columns="deductionColumns"
          :isPagination="false"
          :data="middleRegionVos"
        >
          <el-table-column label="成交订单报价" slot="rank" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.lower }}≤区间＜{{ row.upper }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            :label-width="'200px'"
            slot="operation"
            align="center"
          >
            <template slot-scope="{ row, $index }">
              <el-button
                size="mini"
                type="primary"
                round
                @click="handleBtn(row, 'edit', $index)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                type="danger"
                round
                @click="handleBtn(row, 'del', $index)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </GlobalTable>
        <el-form-item label="生效时间" prop="takeEffectTime">
          <el-date-picker
            v-model="modelform.takeEffectTime"
            :disabled="takeEffectTimeIsEdit"
            type="date"
            :editable="false"
            :picker-options="pickerOptions"
            value-format="timestamp"
            placeholder="请选择生效时间"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="handleDialogClosed">取消</el-button>
        <el-button type="primary" @click.native="handleSuccess">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="dialogMiddleRegionTitle"
      :visible.sync="dialogMiddleRegionVisible"
      :close-on-click-modal="false"
      width="550px"
      @closed="middleRegionDialogClosed"
    >
      <div class="rules-form">
        <div class="rule-title">
          <img class="tip_ico" src="@/assets/images/ico_ts_red.png" alt />
          <span>说明:按照撮合商家合作后成交订单报价进行扣款</span>
        </div>
        <el-form
          :model="middleRegion"
          :rules="middleRegionRules"
          ref="middleRegion"
          label-width="82px"
          class="demo-ruleForm"
        >
          <el-form-item label="报价区间:" required>
            <el-col :span="11">
              <el-form-item prop="lower">
                <el-input
                  v-model.trim="middleRegion.lower"
                  oninput="this.value=value.replace(/[^0-9]/g,'')"
                  placeholder="输入起始值"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col style="text-align: center" :span="2">~</el-col>
            <el-col :span="11">
              <el-form-item prop="upper">
                <el-input
                  v-model.trim="middleRegion.upper"
                  oninput="this.value=value.replace(/[^0-9]/g,'')"
                  placeholder="输入结束值"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="渠道扣款" prop="channelTax">
            <el-input
              placeholder="请输入0或正整数"
              maxlength="10"
              v-model.trim="middleRegion.channelTax"
              @input="
                moneyInput(middleRegion, 'channelTax', middleRegion.channelTax)
              "
            >
              <template slot="append">次（收货次数）</template>
            </el-input>
          </el-form-item>
          <el-form-item label="繁星扣款" prop="btstarsTax">
            <el-input
              placeholder="请输入0或正整数"
              maxlength="10"
              v-model.trim="middleRegion.btstarsTax"
              @input="
                moneyInput(middleRegion, 'btstarsTax', middleRegion.btstarsTax)
              "
            >
              <template slot="append">次（收货次数）</template>
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="dialogMiddleRegionVisible = false"
          >取消</el-button
        >
        <el-button type="primary" @click.native="middleRegHandleSuccess"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="删除提示"
      :visible.sync="dialogDelVisible"
      :close-on-click-modal="false"
      width="380px"
      @closed="dialogDelVisible = false"
    >
      <div class="del-title">
        <div class="sure_tip">确认要删除该数据吗？</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="dialogDelVisible = false">取消</el-button>
        <el-button type="primary" @click.native="handleSuccessDel"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="安全验证"
      :visible.sync="commandDisableShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="commandDialogClosed"
    >
      <div class="command2">
        <div style="width: max-content">谷歌验证码：</div>
        <el-input
          v-model="modelform.command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandDisableShow = false">取消</el-button>
        <el-button
          type="primary"
          @click.native="commandDisableSure"
          :loading="butLoading"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
let checkPrice = (rule, value, callback) => {
  if (value < 50) {
    callback(new Error("扣款条件不能小于50元"));
  } else {
    callback();
  }
};
export default {
  name: "Table",
  props: {
    merchantSelectList: {
      type: Array,
    },
    // allMerchantSelectList: {
    //   type: Array,
    // },
    channelSelectList: {
      type: Array,
    },
  },
  data() {
    return {
      loading: false,
      seachDataList: [],
      commandShow: false,
      butLoading: false,
      commandTitle: "",
      command: "",
      matchId: "",
      dialogVisible: false,
      takeEffectTimeIsEdit: false,
      rowId: "",
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      pageNum: 1,
      companySelectList: [],
      alreadyCompanyList: [],
      tableColumns: [
        { slotName: "matchChannelName" },
        { label: "撮合回收商家", prop: "merchantName" },
        { slotName: "companyName" },
        { slotName: "regionStr" },
        // { slotName: 'takeEffectPrice' },
        // { label: "渠道扣款（次）", prop: "channelTax" },
        // { label: "繁星扣款（次）", prop: "btstarsTax" },
        { label: "创建时间", prop: "createTime" },
        { label: "生效时间", prop: "takeEffectTime" },
        {slotName:"updateAdminName"},
        {slotName:"updateTime"},
        { slotName: "state" },
        { slotName: "operation" },
      ],
      matchChannelName: "",
      merchantName: "",
      companyName: "",
      modelform: {
        matchChannelId: "",
        merchantId: "",
        companyId: "",
        takeEffectTime: "",
        command: "",
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now(); //禁止选择今天和今天之前的时间
        },
      },
      modelrules: {
        // matchChannelId: [
        //   {
        //     required: true,
        //     message: "请选择撮合渠道",
        //     trigger: ["blur", "change"],
        //   },
        // ],
        // merchantId: [
        //   {
        //     required: true,
        //     message: "请选择回收商家",
        //     trigger: ["blur", "change"],
        //   },
        // ],
        // companyId: [
        //   {
        //     required: true,
        //     message: "请选择门店商家",
        //     trigger: ["blur", "change"],
        //   },
        // ],
        // channelTax: [
        //   { required: true, message: '请输入渠道扣款', trigger: 'blur' },
        // ],
        // btstarsTax: [
        //   { required: true, message: '请输入繁星扣款', trigger: 'blur' },
        // ],
        // takeEffectPrice: [
        //   { required: true, message: '请输入扣款条件', trigger: 'blur' },
        //   { type: 'number',validator: checkPrice, trigger: 'blur'},
        // ],
        takeEffectTime: [
          {
            required: true,
            message: "请选择生效时间",
            trigger: ["blur", "change"],
          },
        ],
        // command: [
        //   { required: true, message: '请输入谷歌动态口令', trigger: 'blur' },
        // ]
      },
      middleButLoading: false,
      middleRegionVos: [],
      deductionColumns: [
        { slotName: "rank" },
        { label: "渠道扣款(次/收货次数)", prop: "channelTax" },
        { label: "繁星扣款(次/收货次数)", prop: "btstarsTax" },
        { slotName: "operation" },
      ],
      middleRegionRules: {
        lower: [
          { required: true, message: "请输入报价区间起始值", trigger: "blur" },
        ],
        upper: [
          { required: true, message: "请输入报价区间结束值", trigger: "blur" },
        ],
        channelTax: [
          { required: true, message: "请输入渠道扣款", trigger: "blur" },
        ],
        btstarsTax: [
          { required: true, message: "请输入繁星扣款", trigger: "blur" },
        ],
      },
      middleRowId: "",
      middleRegion: {
        lower: "",
        upper: "",
        btstarsTax: "",
        channelTax: "",
      },
      dialogMiddleRegionTitle: "",
      dialogMiddleRegionVisible: false,
      dialogDelVisible: false,
      commandDisableShow: false,
      tableOperation: "",
    };
  },
  created() {
    this.handleCurrentChange();
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.page.pageNum = 1;
      this.handleCurrentChange();
    },
    "modelform.merchantId"(val) {
      if (!val) {
        this.modelform.companyId = "";
      }
    },
  },
  methods: {
    moneyInput(obj, prop, value) {
      value = "" + value.replace(/[^0-9]/g, "");
      if (!value) {
        this.$set(obj, prop, "");
        return;
      }
      value = parseInt(value);
      this.$set(obj, prop, value);
    },
    // 表格切换页面
    handleCurrentChange(val) {
      // this.getMatchCompanyList();
      if (val) {
        this.pageNum = val;
        this.page.pageNum = val;
      }
      const baseRequest = {
        merchantId: this.SeachParams.merchantId,
        companyId: this.SeachParams.companyId,
        endTime: this.SeachParams.endTime,
        matchChannelId: this.SeachParams.matchChannelId,
        startTime: this.SeachParams.startTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true;
      _api.matchChannelList(baseRequest).then((res) => {
        if (res.code === 1) {
          this.seachDataList = res.data.records;
          this.page.total = res.data.total;
          this.page.pageNum = res.data.current;
          this.loading = false;
        }
        
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }      
      });
    },
    disableopen(row) {
      this.commandTitle = "关闭提示";
      this.commandShow = true;
      this.matchId = row.id;
    },
    // 关闭弹框关闭
    commandClosed() {
      this.commandShow = false;
      this.command = "";
    },
    // 确认关闭弹框
    submit() {
      if (!this.command) {
        this.$message.error("请输入动态口令");
        return;
      }
      this.butLoading = true;
      let params = {
        id: this.matchId,
        command: this.command,
      };
      _api.closeMatchTransaction(params).then((res) => {
        this.$message.success(res.msg || "操作成功");
        this.butLoading = false;
        this.commandShow = false;
        this.command = "";
        this.handleCurrentChange();
      });
    },
    //
    editBtn(row) {
      this.rowId = row.id;
      this.dialogVisible = true;
      this.takeEffectTimeIsEdit = row.state == "01";
      console.log(new Date(row.takeEffectTime).getTime());
      this.modelform = {
        matchChannelId: row.matchChannelId,
        id: row.id,
        merchantId: row.merchantId,
        companyId: row.companyId,
        takeEffectTime: new Date(row.takeEffectTime).getTime(),
      };
      this.matchChannelName = row.matchChannelName;
      this.merchantName = row.merchantName;
      this.companyName = row.companyName;
      this.middleRegionVos = JSON.parse(JSON.stringify(row.regionDtoList));
    },
    // 获取门店商家
    getMatchCompanyList() {
      _api.matchCompanyList({ pageNum: 1, pageSize: 999 }).then((res) => {
        if (res.code === 1) {
          this.alreadyCompanyList = res.data;
        }
      });
    },
    // 确认修改
    handleSuccess() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (!this.middleRegionVos.length) {
            this.$message.error("请设置扣款条件");
            return;
          }
          this.commandDisableShow = true;
          // let params = {
          //   // matchChannelId: this.modelform.matchChannelId,
          //   id: this.modelform.id,
          //   channelTax: this.modelform.channelTax,
          //   btstarsTax: this.modelform.btstarsTax,
          //   takeEffectPrice: this.modelform.takeEffectPrice,
          //   takeEffectTime: this.modelform.takeEffectTime,
          //   command: this.modelform.command,
          // };
          // _api.editMatchTransaction(params).then((res) => {
          //   this.$message.success("成功");
          //   this.dialogVisible = false;
          //   this.handleCurrentChange();
          // });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleDialogClosed() {
      this.$refs["form"].resetFields();
      this.dialogVisible = false;
    },
    // 添加扣款规则
    addRule() {
      this.tableOperation = "add";
      this.middleRowId = "";
      this.dialogMiddleRegionTitle = "添加规则-撮合扣款";
      this.dialogMiddleRegionVisible = true;
    },
    // 扣款条件表格操作
    handleBtn(row, type, index) {
      this.middleRowId = index;
      this.tableOperation = type;
      if (type == "edit") {
        this.dialogMiddleRegionTitle = "编辑规则-撮合扣款";
        this.middleRegion.lower = row.lower;
        this.middleRegion.upper = row.upper;
        this.middleRegion.btstarsTax = row.btstarsTax;
        this.middleRegion.channelTax = row.channelTax;
        this.dialogMiddleRegionVisible = true;
      } else {
        this.dialogDelVisible = true;
      }
    },
    middleRegionDialogClosed() {
      this.middleRegion = {
        lower: "",
        upper: "",
        btstarsTax: "",
        channelTax: "",
      };
      this.$refs["middleRegion"].resetFields();
    },
    // 添加规则-撮合扣款弹框确定
    middleRegHandleSuccess() {
      this.$refs["middleRegion"].validate((valid) => {
        if (valid) {
          let middleRegion = this.middleRegion;
          if (Number(middleRegion.lower) > Number(middleRegion.upper)) {
            this.$message.error("起始值不能大于结束值");
            return;
          }
          if (Number(middleRegion.lower) == Number(middleRegion.upper)) {
            this.$message.error("起始值不能和结束值相等");
            return;
          }
          let params = {
            lower: middleRegion.lower,
            upper: middleRegion.upper,
            btstarsTax: middleRegion.btstarsTax,
            channelTax: middleRegion.channelTax,
          };
          // if (this.middleRegionVos.length && this.tableOperation != "edit") {
          //   let numArr = [];
          //   this.middleRegionVos.forEach((item) => {
          //     numArr.push(item.lower);
          //     numArr.push(item.upper);
          //   });
          //   let iMin = Math.min(...numArr); //最小值
          //   let iMAX = Math.max(...numArr); //最大值
          //   if (middleRegion.upper != iMin && middleRegion.lower != iMAX) {
          //     this.$message.error("价位区间不连续");
          //     return;
          //   }
          //   if (middleRegion.lower != iMAX && middleRegion.upper != iMin) {
          //     this.$message.error("价位区间不连续");
          //     return;
          //   }
          // }
          // params.btstarsTax = middleRegion.btstarsTax;
          // params.channelTax = middleRegion.channelTax;
          if (this.middleRowId.toString()) {
            this.middleRegionVos.splice(this.middleRowId, 1, params);
          } else {
            this.middleRegionVos.push(params);
          }
          this.dialogMiddleRegionVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 删除弹框确认
    handleSuccessDel() {
      this.middleRegionVos.splice(this.middleRowId, 1);
      this.dialogDelVisible = false;
    },
    commandDialogClosed() {
      this.modelform.command = "";
    },
    // 谷歌验证弹框确定
    commandDisableSure() {
      let params = {
        middleRegionVos: this.middleRegionVos,
        ...this.modelform,
      };
      this.middleButLoading = true;
      _api
        .editMatchTransaction(params)
        .then((res) => {
          this.$message.success("操作成功");
          this.dialogVisible = false;
          this.handleCurrentChange();
          this.commandDisableShow = false;
          this.middleButLoading = false;
          this.middleRegionVos = [];
        })
        .catch(() => {
          this.middleButLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }
  .operation-btn {
    /deep/.el-button {
      margin-left: 0;
    }
  }
  .tableBtn {
    color: #0981ff;
    cursor: pointer;
  }
  .enable-tip {
    color: #ff8080;
    font-size: 14px;
    margin-bottom: 20px;
    .tip-sure {
      margin-top: 10px;
      color: #333333;
      font-size: 16px;
      text-align: center;
    }
  }
  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .add-form {
    /deep/.el-input-group__append {
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }
    /deep/.el-input-group__prepend {
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }
    /deep/.el-select {
      width: 100%;
    }
  }
  .rules-form {
    .rule-title {
      display: flex;
      align-items: center;
      color: #ff8080;
      margin-bottom: 20px;
    }
    .tip_ico {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }
    /deep/.el-input-group__append {
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }
  }
  .del-title {
    color: #ff8080;
    font-size: 12px;
    .sure_tip {
      margin-top: 15px;
      font-size: 16px;
      color: #333333;
    }
  }
  .command2 {
    display: flex;
    align-items: center;
    /deep/.el-input {
      width: 300px;
    }
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .flex-jb {
    display: flex;
    justify-content: space-between;
  }
  .flex1 {
    flex: 1;
  }
  .overflow-hiding {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-break: break-all;
    -webkit-line-clamp: 1;
    /*! autoprefixer: off */
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    /*! autoprefixer: on */
  }
}
</style>
